<script>
    import {_} from 'svelte-i18n'
    import * as graduationEventParticipantService from "../services/graduationEventParticipantService"
    import {graduationEventParticipant} from "../stores/graduationEventParticipant"
    import {failure, success} from "../lib/toast"
    import {asset} from "../lib/utils"
    import QrScanner from 'qr-scanner'
    import {onMount, tick} from "svelte"
    import Input from "../components/form/Input"

    let loading = true
    let qrScanner = null
    let flashlightIsOn = false
    let scanResultResponse = null
    let showScanAgainOverlay = false
    let manualTicketCode = null
    let runningTime = null
    let remainingTime = null
    let scanAgainIn = null
    let timer = null
    let timeout = null

    $: console.log(flashlightIsOn)

    onMount(() => {
        qrScanner = new QrScanner(
            document.getElementById('scanner'),
            result => handleResult(result),
            {
                onDecodeError: (error) => handleError(error),
                highlightScanRegion: true,
                maxScansPerSecond: 10
            }
        );

        qrScanner.setCamera('environment')

        loading = false
    })

    const handleResult = (result) => {
        stopScanner()

        checkin(result.data)
    }

    const handleError = (error) => {
        startScanner()

        console.log(error)
    }

    const startScanner = () => {
        // TODO: Scan Ergebnis soll weiterhin angezeigt werden auch wenn Scanner erneut gestartet

        showScanAgainOverlay = false
        qrScanner.start()
    }

    const stopScanner = () => {
        qrScanner.stop()
    }

    const flashlightOn = () => {
        flashlightIsOn = true
        qrScanner.turnFlashOn()
    }

    const flashlightOff = () => {
        flashlightIsOn = false
        qrScanner.turnFlashOff()
    }

    const toggleFlash = () => {
        flashlightIsOn
            ? flashlightOff
            : flashlightOn
    }

    const handleManualSubmit = (e) => {
        e.preventDefault()

        stopScanner()

        checkin(manualTicketCode)
        console.log(manualTicketCode)

        // set to null after succesfull checkin
        manualTicketCode = null
    }

    const checkin = (ticket_code) => {
        loading = true

        graduationEventParticipantService.checkin(ticket_code)
            .then( (response) => {

                // start the Scanner after succesfull response
                remainingTime = 20
                scanAgainIn = remainingTime * 1000
                showScanAgainOverlay = true
                runningTime = 0

                clearInterval(timer)
                clearTimeout(timeout)

                timeout = setTimeout( function() {
                    showScanAgainOverlay = false
                    startScanner()

                    // reset the Timer
                    clearInterval(timer)
                }, scanAgainIn)

                timer = setInterval( function() {
                    runningTime = runningTime + 1000
                    remainingTime = (scanAgainIn - runningTime) / 1000
                }, 1000)

                scanResultResponse = response.data
            })
            .catch( (response) => {
                scanResultResponse = null

                failure($_('Es gab einen Fehler beim scannen des Tickets bitte versuchen Sie es erneut'))

                // start the Scanner again
                startScanner()
            })
            .finally( () => {
                loading = false
            })
    }
</script>

<main>
    <div class="flex items-center justify-center h-full w-full bg-gray-300" style="background: url({asset('images/master-graduation03.jpg')}); min-height: var(--appHeight);">
        <div class="absolute top-0 left-0 w-full h-full backdrop-brightness-50"></div>
        <div class="w-full md:w-2/3 lg:w-3/4 bg-white mx-auto container p-4 my-16 z-10">
            <h1 class="text-xl">
                {$_('Checkin Graduation Teilnehmer*innen')}
            </h1>

            <div>
                <p>
                    {$_('Wenn kein QR-Code erkannt wird bitte Bildschirmhelligkeit verringern oder Licht anschalten')}
                </p>

                <div class="flex flex-col lg:flex-row mt-10">
                    <div class="w-full lg:w-1/2 pb-4 lg:pb-0 lg:pr-4 border-b lg:border-b-0 lg:border-r border-gray-400">

                        <h2 class="text-lg mb-2">
                            {$_('Scan Ergebnis')}:
                        </h2>
                        
                        {#if scanResultResponse}
                            <div class="mt-4">
                                {#if scanResultResponse.data.status === 'already_scanned'}
                                    <div class="bg-red-600 text-white p-2 rounded-md w-full">
                                        Ticket wurde bereits gescannt
                                    </div>
                                {:else}
                                    <div class="bg-green text-white p-2 rounded-md w-full">
                                        Ticket wurde erfolgreich gescannt
                                    </div>
                                {/if}
                            </div>

                            <div class="mt-4">
                                <table>
                                    <tr>
                                        <td class="px-2 py-1">
                                            {$_('Name')}:
                                        </td>
                                        <td class="px-2 py-1">
                                            {scanResultResponse.data.first_name} {scanResultResponse.data.last_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="px-2 py-1">
                                            {$_('E-Mail')}:
                                        </td>
                                        <td class="px-2 py-1">
                                            {scanResultResponse.data.email}
                                        </td>
                                    </tr>
                                    {#if scanResultResponse.data.graduationEventTimeSlot}
                                        <tr>
                                            <td class="px-2 py-1">
                                                {$_('Veranstaltung')}:
                                            </td>
                                            <td class="px-2 py-1">
                                                {scanResultResponse.data.graduationEventTimeSlot.title}
                                            </td>
                                        </tr>
                                    {/if}
                                </table>
                            </div>
                        {/if}
                    </div>
                    <div class="w-full lg:w-1/2 pt-4 lg:pt-0 lg:pl-4 flex flex-col justify-between">
                        <div class="relative h-[225px]">
                            {#if showScanAgainOverlay}
                                <div class="absolute top-0 left-0 w-full h-full flex items-center z-10">
                                    <div>
                                        <p class="text-2xl">
                                            {$_('Scanner startet erneut in')}: {remainingTime}
                                        </p>

                                        <button disabled="{loading}" on:click="{startScanner}" class="button secondary justify-center mt-2">
                                            <span>
                                                {$_('Jetzt scannen')}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            {/if}
                            <video id="scanner" class="object-right max-h-full">
                                <track kind="captions"/>
                            </video>
                        </div>
                        <div>
                            <form on:submit="{handleManualSubmit}" class="flex flex-row gap-2 mt-4">
                                <Input 
                                    bind:value={manualTicketCode}
                                    name="ticketid"
                                    inputClass="w-full !m-0" 
                                    type="text"
                                    placeholder="Ticket ID"
                                    containerClass="grow"
                                />

                                <button disabled="{loading}" on:click="{handleManualSubmit}" class="button primary justify-center">
                                    <span class="mr-1">
                                        {$_('Checkin')}
                                    </span>
                                </button>
                            </form>
                        </div>
                        <div class="flex flex-row gap-2 flex-wrap mt-4 justify-between">
                            <button disabled="{loading}" on:click="{startScanner}" class="button primary justify-center">
                                <span class="mr-1">
                                    {$_('Scannen')}
                                </span>
                            </button>
                            {#if qrScanner && qrScanner.hasFlash()}
                                <div on:click="{toggleFlash}">
                                    <label class="swap swap-rotate text-green">
                                        <!-- this hidden checkbox controls the state -->
                                        <input type="checkbox"/>
                                        <!-- sun icon -->
                                        <svg class="swap-on fill-current w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/></svg>
                                        <!-- moon icon -->
                                        <svg class="swap-off fill-current w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/></svg>
                                    </label>
                                </div>
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>