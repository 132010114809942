<script>
    import {Link} from "svelte-routing";
    import {createEventDispatcher} from "svelte"
    
    const event = new Event('routechange');

    export let to = ""
    export let linkClass = ""

    const dispatch = createEventDispatcher()

    let isActive = false

    const getProps = ({ location, href, isPartiallyCurrent, isCurrent }) => {
        isActive = href === "/" ? isCurrent : isPartiallyCurrent || isCurrent || window.location.pathname == to.slice(0, -1);
    }

    const clickHandler = () => {
        dispatch('click')
    }
</script>

<Link to="{to}" getProps="{getProps}" class="{linkClass} {isActive ? 'active' : 'inactive'}" on:click="{() => clickHandler()}">
    <slot/>
</Link>