<script>
    
    import {clickOutside, asset} from "../lib/utils"
    import NavLink from "./NavLink.svelte";
    import LocaleSwitcher from "./LocaleSwitcher.svelte";
    import {routes} from "../routes"
    import {locale} from 'svelte-i18n'

    export let hide

    let sidebarOpen = false
    let scrolled = false

    let initialHeaderHeight = 80
    let scrolledHeaderHeight = 60
    $: headerHeight = scrolled ? scrolledHeaderHeight : initialHeaderHeight
    
    const toggleSidebar = () => sidebarOpen = !sidebarOpen

    document.addEventListener('scroll', () => {
        scrolled = window.scrollY > 0
    }, {
        passive: true
    });

    document.documentElement.style.setProperty('--appHeight', `calc(100vh - ${initialHeaderHeight}px)`);
</script>

<main class="fixed top-0 w-[inherit] z-40 {hide ? 'hidden' : ''}">
    <div class="flex flex-row items-center shadow-md transition-all ease-in-out bg-white" style="height: {headerHeight}px">
        <div class="container mx-auto flex flex-row items-center justify-between w-full h-full gap-8">
            <div class="flex flex-row gap-8 lg:grow-1 lg:justify-between lg:grow">
                <div class="w-[150px] max-h-full">
                    <NavLink to="/">
                        {#if $locale === 'de-DE' || $locale === 'de'}
                            <img src="{asset('images/logo.png')}" class="transition-all ease-in-out object-contain" style="height: {headerHeight - 30}px" alt="Uni St. Gallen"/>
                        {:else}
                            <img src="{asset('images/logo.png')}" class="transition-all ease-in-out object-contain" style="height: {headerHeight - 30}px" alt="Uni St. Gallen"/>
                        {/if}
                    </NavLink>
                </div>

                <div class="hidden lg:flex flex-row gap-4 items-center">
                    {#each routes as route}
                        {#if route.menu}
                            <NavLink to="{route.path}">
                                {route.name}
                            </NavLink>
                        {/if}
                    {/each}
                </div>
            </div>

            <div class="flex flex-row gap-4 items-center">
                <LocaleSwitcher/>

                <div class="flex flex-row gap-8 h-full">
                    <div use:clickOutside on:click_outside="{() => sidebarOpen = false}" class="flex items-center">
                        <div on:click="{toggleSidebar}" class="w-auto h-auto flex items-center justify-center rounded-md outline-none focus:outline-none appearance-none relative cursor-pointer">
                            <div class="relative w-6 h-8 flex justify-center items-center">
                                <span class="block absolute right-0 h-0.5 transform transition duration-500 ease-in-out bg-black {sidebarOpen ? 'rotate-45 w-6' : 'w-6 -translate-y-2'}"/>
                                <span class="block absolute right-0 h-0.5 transform transition duration-500 ease-in-out bg-black {sidebarOpen ? 'opacity-0 w-6' : 'w-6'}"/>
                                <span class="block absolute right-0 h-0.5 transform transition duration-500 ease-in-out bg-black {sidebarOpen ? '-rotate-45 w-6' : 'w-2 translate-y-2'}"/>
                            </div>
                        </div>

                        <div class="shadow-md ease-in-out transition-all duration-300 bg-white text-black fixed top-0 bottom-0 right-0 w-full md:w-[400px] z-50 transform {sidebarOpen ? 'translate-x-0' : 'translate-x-full'}" style="top: {headerHeight}px">
                            <div class="h-full overflow-y-scroll">
                                <div class="flex flex-col h-full justify-between">
                                    <div>
                                        {#each routes as route}
                                            {#if route.menu}
                                                <div class="border-t first:border-t-0 last:border-b border-black flex">
                                                    <NavLink to="{route.path}" linkClass="p-4 event-navigation-link w-full" on:click="{() => sidebarOpen = false}">
                                                        {route.name}
                                                    </NavLink>
                                                </div>
                                            {/if}
                                        {/each}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<div class="{hide ? 'hidden' : ''}" style="height: {initialHeaderHeight}px"></div>