<script>
    import {link} from "svelte-routing"

    export let href
    export let title
    export let internalLink
</script>

{#if internalLink}
    <a use:link {title} {href} class="bg-green hover:bg-dark-green text-white transition-all ease-in-out p-6 group block cursor-pointer">
        <div class="flex flex-col justify-between h-full">
            <div>
                <slot name="content"/>
            </div>
            <p class="mt-4 flex items-center gap-4">
                <i class="material-icons mr-2 group-hover:mr-4 transition-all ease-in-out">east</i>
                {title}
            </p>
        </div>
    </a>
{:else}
    <a {title} {href} class="bg-green hover:bg-dark-green text-white transition-all ease-in-out p-6 group block cursor-pointer">
        <div class="flex flex-col justify-between h-full">
            <div>
                <slot name="content"/>
            </div>
            <p class="mt-4 flex items-center gap-4">
                <i class="material-icons mr-2 group-hover:mr-4 transition-all ease-in-out">east</i>
                {title}
            </p>
        </div>
    </a>
{/if}