<script>
    import { onMount } from 'svelte';
    import { _ } from 'svelte-i18n'
    import {asset} from "../lib/utils"

    export let url;

    let pdfjsLib = window['pdfjs-dist/build/pdf'];
    let pageNum = 1
    let pdfDoc = null
    let pageRendering = false
    let scale = 2
    let pageNumPending = null
    let canvas = null
    let ctx = null
    
    pdfjsLib.GlobalWorkerOptions.workerSrc = asset('js/pdf.worker.js');

    function renderPDF(){
        canvas = document.getElementById('pdf-canvas')
        ctx = canvas.getContext('2d')

        document.getElementById('prev').addEventListener('click', prevPage);
        document.getElementById('next').addEventListener('click', nextPage);

        pdfjsLib.getDocument(url).promise.then(function(pdfDoc_) {
            pdfDoc = pdfDoc_;
            document.getElementById('page_count').textContent = pdfDoc.numPages;

            renderPage(pageNum);
        });
    }

    const renderPage = (num) => {
        pageRendering = true;

        pdfDoc.getPage(num).then(function(page) {
            var viewport = page.getViewport({scale: scale});
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            var renderContext = {
                canvasContext: ctx,
                viewport: viewport
            };

            var renderTask = page.render(renderContext);

            renderTask.promise.then(function() {
                pageRendering = false;
                if (pageNumPending !== null) {
                    renderPage(pageNumPending);
                    pageNumPending = null;
                }
            });
        });

        document.getElementById('page_num').textContent = num;

        num === 1
            ? document.getElementById('prev').disabled = true
            : document.getElementById('prev').disabled = false

        num === pdfDoc.numPages
            ? document.getElementById('next').disabled = true
            : document.getElementById('next').disabled = false
    }

    const nextPage = () => {
        if (pageNum >= pdfDoc.numPages) {
            return;
        }
        pageNum++;
        queueRenderPage(pageNum);
    }

    const queueRenderPage = (num) => {
        if (pageRendering) {
            pageNumPending = num;
        } else {
            renderPage(num);
        }
    }

    const prevPage = () =>  {
        if (pageNum <= 1) {
            return;
        }
        pageNum--;
        queueRenderPage(pageNum);
    }

    onMount( () => {
        renderPDF()
    })
</script>

<main class="relative">
    <div class="flex flex-col lg:flex-row bg-black/50 gap-4 sticky p-4 items-center justify-between" style="top: 60px">
        <div class="flex flex-row gap-4">
            <button class="button primary" id="prev">
                {$_('Zurück')}
            </button>
            <button class="button primary" id="next">
                {$_('Weiter')}
            </button>
        </div>
        <span class="paginator">{$_('Seite:')} <span id="page_num"></span> {$_('von')} <span id="page_count"></span></span>
    </div>

    <canvas data-url="{url}" style="width: 100% !important" id="pdf-canvas"></canvas>

    <div class="flex bg-black/50 gap-4 p-4">
        <a href="{url}" target="_blank" download class="button secondary w-auto">
            {$_('PDF herunterladen')}
        </a>
    </div>
</main>