import App from './components/App.svelte'
import {route} from "./stores/route"
import {register, init, getLocaleFromNavigator} from 'svelte-i18n'
import {getJSON} from "./lib/utils"

window.Vapor = require('laravel-vapor');

/**
 * Registering all Language Files
 * and the Fallback locale
 * for the App
 */

register('de', () => import('../lang/de.json'));
register('en', () => import('../lang/en.json'));

init({
    fallbackLocale: 'de',
    initialLocale: getLocaleFromNavigator(),
})
.then( () => {
    console.log('finished loading languages')}
);

/**
 * Setup Pusher for interacting
 * with Websockets to the
 * Frontend Application
 */

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    wsHost: process.env.MIX_PUSHER_HOST,
    wsPort: process.env.MIX_PUSHER_PORT,
    wssPort: process.env.MIX_PUSHER_PORT,
    forceTLS: false,
    encrypted: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
});

import _ from 'lodash';
window._ = _;

import moment from 'moment'
window.moment = moment

/**
 * Bootstraps the Svelte Application
 * on the DOM 'app' Element
 * and exports app
 */
const app = new App({
    target: document.getElementById('app')
})

window.app = app
export default app

/**
 * All Actions wich need to be
 * defined globally on
 * initialization
 */

// Triggers on Route change
history.pushState = new Proxy(history.pushState, {
    apply(target, thisArg, argumentList) {
        Reflect.apply(target, thisArg, argumentList)
        onRouteChanged()
    }
})

// Triggers on Back and Forth Button
addEventListener("popstate", function (e) {
    onRouteChanged()
});

// Trigger all Actions on Route change
const onRouteChanged = () => {
    route.set(window.location)
    
    scrollTo(0, 0)
}

/**
 * Get the User Current
 * Country and save it
 * in the Local Storage
 */

const getUserCountry = () => {
    if (localStorage.getItem("country") === null) {
        getJSON('https://api.ipdata.co/?api-key=ea7c2d157d92012192a5c2d7a01e1787b95202500851fdb616723422', function (err, data) {
            var countryCode = data.country_code;
            localStorage.setItem("country", countryCode);
            console.log("country set in localstorage: " + localStorage.getItem("country"));
        });
    } else {
        console.log("country already set in localstorage: " + localStorage.getItem("country"));
    }
}

getUserCountry();