import {SvelteToast, toast} from '@zerodevx/svelte-toast'

new SvelteToast({
    target: document.body,
    props: {
        options: {
            intro: { y: 1000, x: 0 }
        }
    }
})

export const success = m => toast.push(m, {
    theme: {
        '--toastBackground': 'green',
        '--toastColor': 'white',
        '--toastProgressBackground': 'darkgreen'
    }
})

export const warning = m => toast.push(m, {
    theme: {
        '--toastBackground': 'red',
        '--toastColor': 'white',
        '--toastProgressBackground': 'darkred'
    }
})

export const failure = m => toast.push(m, {
    theme: {
        '--toastBackground': 'red',
        '--toastColor': 'white',
        '--toastProgressBackground': 'darkred'
    }
})