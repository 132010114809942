import { writable } from "svelte/store";

export const clickOutside = (node) => {
    const handleClick = event => {
        if (node && !node.contains(event.target) && !event.defaultPrevented) {
            node.dispatchEvent(
                new CustomEvent('click_outside', node)
            )
        }
    }

    document.addEventListener('click', handleClick, true);
    
    return {
        destroy() {
            document.removeEventListener('click', handleClick, true);
        }
	}
}

export const syncHeight = (element) => {
    return writable(null, (set) => {
        if (!element) {
            return;
        }
        let resizeObserver = new ResizeObserver(() => element && set(element.offsetHeight));
        resizeObserver.observe(element);
        return () => resizeObserver.disconnect();
    });
}

export const formatDateRange = (starting_at, ending_at) => {

    starting_at = moment(starting_at)
    ending_at = moment(ending_at)

    if(starting_at && ending_at) {
        if(starting_at.format('DD.MM.YYYY') != ending_at.format('DD.MM.YYYY')) {
            return starting_at.format('DD.MM.YYYY') + ' - ' + ending_at.format('DD.MM.YYYY') + ' | ' + starting_at.format('HH:mm') + ' - ' + ending_at.format('HH:mm') + ' Uhr'
        }
        else {
            return starting_at.format('DD.MM.YYYY') + ' | ' + starting_at.format('HH:mm') + ' - ' + ending_at.format('HH:mm') + ' Uhr'
        }
    }
    else if(starting_at) {
        return starting_at.format('DD.MM.YYYY') + ' - ' + starting_at.format('DD.MM.YYYY')
    }
}

export const getVerticalScrollPercentage = () => {
    return (document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight ) * 100
}

export const getVerticalScroll = () => {
    return document.documentElement.scrollTop
}


export const getJSON = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    xhr.onload = function() {
        var status = xhr.status;
        if (status === 200) {
            callback(null, xhr.response);
        } else {
            callback(status, xhr.response);
        }
    };
    xhr.send();
};

export const readableFileSize = (bytes, si=false, dp=1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si 
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    
    let unit = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++unit;
    } 
    while (Math.round(Math.abs(bytes) * r) / r >= thresh && unit < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[unit];
}

export const slugify = (string) => {
    return string
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
}

export const toCurrency = (value, currency, minimumFractionDigits = 2) => {
    if (!currency) {
        currency = 'EUR'
    }
    if (typeof value !== "number") {
        return value
    }
    var formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: minimumFractionDigits
    })
    return formatter.format(value)
}

export const countLines = (target) => {
    var style = window.getComputedStyle(target, null);
    var height = parseInt(style.getPropertyValue("height"));
    var font_size = parseInt(style.getPropertyValue("font-size"));
    var line_height = parseInt(style.getPropertyValue("line-height"));
    var box_sizing = style.getPropertyValue("box-sizing");

    if (isNaN(line_height)) line_height = font_size * 1.2;

    if (box_sizing == 'border-box') {
        var padding_top = parseInt(style.getPropertyValue("padding-top"));
        var padding_bottom = parseInt(style.getPropertyValue("padding-bottom"));
        var border_top = parseInt(style.getPropertyValue("border-top-width"));
        var border_bottom = parseInt(style.getPropertyValue("border-bottom-width"));
        height = height - padding_top - padding_bottom - border_top - border_bottom
    }
    var lines = Math.ceil(height / line_height);
    return lines;
}

export const asset = (file) => {
    return file
}

export const pad = (size) => {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}