<script>
    import { _ } from 'svelte-i18n'
    import {asset} from "../../lib/utils"
</script>

<main class="relative" style="min-height: var(--appHeight); background: url({asset('images/master-graduation01.jpg')})">
    <div class="absolute top-0 left-0 w-full h-full backdrop-brightness-50"></div>
    <div class="py-32 z-10 text-white relative">
        <div class="container mx-auto p-8 bg-black/30">
            <h1>
                {$_('Einlass')}
            </h1>

            <div class="mt-6">
                <strong>
                    {$_('Einlass Graduierende:')}
                </strong>
                <p>
                    {$_('Graduierende und Gäste betreten die Halle von unterschiedlichen an unterschiedlichen Eingängen. Bitte denken Sie daran, dass Sie und ihre Gäste jeweils ihre Tickets beim Einlass dabei haben. Sie werden gleich nach dem Einlass und Check-in von der Leitung Graduation Days in die Halle begleitet und nehmen anschliessend den für Sie vorgesehenen Platz ein (weitere Informationen dazu werden im Briefing erläutert). Bitte erscheinen Sie möglichst genau 45 Minuten vor der Feier am Einlass.')}
                </p>
            </div>

            <div class="mt-6">
                <strong>
                    {$_('Einlass Gäste:')}
                </strong>
                <p>
                    {$_('Ca. 35 Minuten vor der Feier wird der ausgewiesene Einlass für Ihre Gäste eröffnet. Bitte stellen Sie sicher, dass Ihre Gäste ein Ticket haben und dieses elektronisch oder ausgedruckt zur Zeremonie mitbringen: Nur Gäste mit gültigem Ticket können für den Event einchecken und an der Zeremonie teilnehmen lassen. Wenn Sie einen Gast mit einer Gehbehinderung in Begleitung haben, beachten Sie bitte den barrierefreien Zugang zur Halle. Zögern Sie nicht sich an uns zu wenden, wenn Ihre älteren Angehörigen vorzeitig einen Sitzplatz einnehmen müssen. Gerne dürfen Sie oder Ihre Gäste in Begleitung von Kindern und Kleinkindern zur Zeremonie erscheinen. Alle Kinder und Kleinkinder, die einen separaten Stuhl benötigen brauchen ein Ticket. Wir bitten Sie höflich, auf das Mitnehmen Ihrer vierbeinigen Familienmitglieder sowie anderer Haustiere zu verzichten. Wir bitten Gäste ihre gesamte Garderobe nach der Zeremonie in die Apéro Halle mitzunehmen, da der Zugang zum Auditorium nach dem Auszug geschlossen wird.')}
                </p>
            </div>

            <div class="mt-6">
                <strong>
                    {$_('Nach der Feier:')}
                </strong>
                <p>
                    {$_('Die Graduierenden werden feierlich aus der Halle ausziehen. Genauere Informationen dazu erhalten Sie im Briefing. Anschliessend laden wir Sie und Ihre Angehörigen zu einem Apéro ein. Wir bitten Sie höflich, das Auditorium nach der Zeremonie zu verlassen, damit wir eine Reinigung für die Anschlusszeremonie vornehmen können. Ihre Garderobe wird für Sie in die Apéro Halle gebracht.  Da nur begrenzt Parkplätze zur Verfügung stehen, bitten wir Sie, das Parkhaus nach Ihrer Feier wieder zu verlassen damit die nächsten Graduierenden einen freien Platz finden.')}
                </p>
            </div>
        </div>
    </div>
</main>