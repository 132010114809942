<script>
    import { _ } from 'svelte-i18n'
    import BigLink from "../components/BigLink.svelte";
    import {asset} from "../lib/utils"
</script>

<div class="flex items-center justify-center" style="background: url({asset('images/master-graduation02.jpg')}); min-height: var(--appHeight);">
    <div class="absolute top-0 left-0 w-full h-full backdrop-brightness-50"></div>
    <div class="py-16 container mx-auto z-10">
        <div class="w-full md:w-3/4 lg:w-2/4 mx-auto">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <BigLink href="/bachelor-graduation" title="{$_('zur Bachelor Graduation')}" internalLink="true">
                    <div slot="content">
                        <p class="text-2xl">
                            {$_('Bachelor Graduation')}
                        </p>
                    </div>
                </BigLink>
                <BigLink href="/master-graduation" title="{$_('zur Master Graduation')}" internalLink="true">
                    <div slot="content">
                        <p class="text-2xl">
                            {$_('Master Graduation')}
                        </p>
                    </div>
                </BigLink>
            </div>

            <div class="mt-8">
                
                <h2 class="text-white">
                    {$_('Programmfotos Master')}
                </h2>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <BigLink href="/master-galerie" title="{$_('zur Galerie')}" internalLink="true">
                        <div slot="content">
                            <p class="text-2xl">
                                {$_('Galerie')}
                            </p>
                        </div>
                    </BigLink>
                </div>
            </div>

            <div class="mt-8">
                
                <h2 class="text-white">
                    {$_('Bachelor Galerie')}
                </h2>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <BigLink href="/bachelor-galerie-1" title="{$_('zur Galerie')}" internalLink="true">
                        <div slot="content">
                            <p class="text-2xl">
                                {$_('Erste Feier')}
                            </p>
                        </div>
                    </BigLink>
                    <BigLink href="/bachelor-galerie-2" title="{$_('zur Galerie')}" internalLink="true">
                        <div slot="content">
                            <p class="text-2xl">
                                {$_('Zweite Feier')}
                            </p>
                        </div>
                    </BigLink>
                    <BigLink href="/bachelor-galerie-3" title="{$_('zur Galerie')}" internalLink="true">
                        <div slot="content">
                            <p class="text-2xl">
                                {$_('Dritte Feier')}
                            </p>
                        </div>
                    </BigLink>
                    <BigLink href="/bachelor-galerie-4" title="{$_('zur Galerie')}" internalLink="true">
                        <div slot="content">
                            <p class="text-2xl">
                                {$_('Vierte Feier')}
                            </p>
                        </div>
                    </BigLink>
                </div>
            </div>

            <div class="mt-8">
                
                <h2 class="text-white">
                    {$_('Master Galerie')}
                </h2>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <BigLink href="/master-galerie-1" title="{$_('zur Galerie')}" internalLink="true">
                        <div slot="content">
                            <p class="text-2xl">
                                {$_('Erste Feier')}
                            </p>
                        </div>
                    </BigLink>
                    <BigLink href="/master-galerie-2" title="{$_('zur Galerie')}" internalLink="true">
                        <div slot="content">
                            <p class="text-2xl">
                                {$_('Zweite Feier')}
                            </p>
                        </div>
                    </BigLink>
                    <BigLink href="/master-galerie-3" title="{$_('zur Galerie')}" internalLink="true">
                        <div slot="content">
                            <p class="text-2xl">
                                {$_('Dritte Feier')}
                            </p>
                        </div>
                    </BigLink>
                </div>
            </div>
        </div>
    </div>
</div>