<script>
    import Input from "../../components/form/Input"
    import { _ } from 'svelte-i18n'
    import {asset, formatDateRange} from "../../lib/utils"
    import * as tempEventService from "../../services/tempEventService"
    import {tempEvent, tempEvents} from "../../stores/tempEvent"
    import {route} from "../../stores/route"

    let currentEvent = null
    let registration = {}
    let formLoading = false

    const getTempEvents = async () => {
        return await tempEventService.index({
            params: {
                type: [localStorage.getItem('lastpage')]
            }
        })
    }

    const register = async () => {
        formLoading = true
        
        registration.temp_event_id = currentEvent.id

        return await tempEventService.register(registration)
        .then( () => {
            formLoading = false
            closeRegisterModal()
        })
        .finally( () => {
            formLoading = false
        })
    }

    const openRegisterModal = (tempEvent) => {
        document.getElementById('register-modal').checked = true;

        currentEvent = tempEvent
        registration = {
            name: null,
            email: null,
            temp_event_id: null
        }
    }

    const closeRegisterModal = () => {
        document.getElementById('register-modal').checked = false;

        currentEvent = {}
        registration = {
            name: null,
            email: null,
            temp_event_id: null
        }
    }

    getTempEvents()

    $: $route && getTempEvents()
</script>

<main class="relative" style="min-height: var(--appHeight); background: url({asset('images/master-graduation01.jpg')})">
    <div class="absolute top-0 left-0 w-full h-full backdrop-brightness-50"></div>
    <div class="py-32 z-10 text-white relative">
        <div class="container mx-auto p-8 bg-black/30">
            <h1>
                {$_('Ihr Rahmenprogramm')}
            </h1>

            <div class="mt-6">
                <p>
                    {$_('Wenn Sie Ihren Nächsten die Stadt St.Gallen zeigen möchten, können Sie sich für eine der u.s. Führungen anmelden.')}
                </p>
                <p>
                    {$_('Angebot Stadtführungen:')}
                </p>
            </div>

            {#if $tempEvents}
                <div class="my-12">
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {#each $tempEvents.data as tempEvent}
                            <div class="border border-green p-2">
                                <p class="text-2xl mb-2">
                                    {tempEvent.name}
                                </p>
                                <p class="mb-2">
                                    {formatDateRange(tempEvent.date_time, tempEvent.date_time_end)}
                                </p>

                                <button on:click="{openRegisterModal(tempEvent)}" class="button primary text-center w-full justify-center">
                                    <span>{$_('Registrieren')}</span>
                                </button>
                            </div>
                        {/each}
                    </div>
                </div>
            {/if}

            <input type="checkbox" id="register-modal" class="modal-toggle" />
            <label for="register-modal" class="modal backdrop-brightness-50 cursor-pointer">
                <label class="modal-box relative shadow-md p-4" for="" style="width: 400px">
                    <div class="modal-action mb-2">
                        <label for="register-modal" class="button primary !p-1">
                            x
                        </label>
                    </div>

                    {#if currentEvent}
                        <h3 class="text-lg font-bold">
                            {$_('Registrierung für')}: {currentEvent.name}
                        </h3>
                        <p class="py-4">
                            {$_('Sie melden sich für den folgenden Timeslot an')}: {formatDateRange(currentEvent.date_time, currentEvent.date_time_end)}
                        </p>

                        <div>
                            <Input 
                                bind:value={registration.name} 
                                name="name" 
                                formID="temp-event-registration" 
                                inputClass="w-full" 
                                type="text" 
                                placeholder="{$_('Name')}"
                            />
                            <Input 
                                bind:value={registration.email} 
                                name="email" 
                                formID="temp-event-registration" 
                                inputClass="w-full" 
                                type="text" 
                                placeholder="{$_('E-Mail')}"
                            />
                            <button disabled="{formLoading}" on:click="{register}" class="button primary justify-center">
                                <span class="mr-1">
                                    {$_('Registrieren')}
                                </span>
                            </button>
                        </div>
                    {/if}
                </label>
            </label>

        </div>
    </div>
</main>