<script>
    import {errors} from '../../stores/error';

    let inputError = false

    export let hasErrors = false
    export let formID = 'default'
    export let name = ''

    $: hasErrors = inputError ? true : false

    errors.subscribe( (data) => {
        inputError = _.get(data, `${formID}.${name}`)
    })
</script>

<main>
    { #if inputError }
        <div class="my-1 text-red-500 text-xs">
            {inputError}
        </div>
    { /if }
</main>