<script>
    import { _ } from 'svelte-i18n'
    import {asset} from "../../lib/utils"
</script>

<main class="relative" style="min-height: var(--appHeight); background: url({asset('images/master-graduation01.jpg')})">
    <div class="absolute top-0 left-0 w-full h-full backdrop-brightness-50"></div>
    <div class="py-32 z-10 text-white relative">
        <div class="container mx-auto p-8 bg-black/30">
            <h1>
                {$_('Fotoservice & Fotobox')}
            </h1>

            <div class="mt-6">
                <p>
                    {$_('Erinnerungen an Ihre Graduation Feier können natürlich festgehalten werden. Einige Stimmungsbilder vom Anlass werden wir allen hier auf unserer Seite zur Verfügung stellen. Das Foto von der Diplomübergabe können Sie bei Interesse nach der Feier bei Foto Lautenschlager bestellen.')}
                </p>
                <a class="text-green underline" href="http://www.photoart-gallery.ch/events.cgi?func=eventlist&base=thalmann_unisg" title="Foto Lautenschlager">
                    {$_('Foto Lautenschlager')}
                </a>
            </div>

            <div class="mt-6 flex flex-col lg:flex-row gap-8">
                <div class="w-full lg:w-1/3">
                    <img src="{asset('images/alumni-fotobox.jpg')}" alt="Alumni Fotobox"/>
                </div>
                <div class="w-full lg:w-2/3">
                    <h2>
                        {$_('Alumni Fotobox')}
                    </h2>
                    <p>
                        {$_('Herzliche Gratulation von deiner HSG-Alumni-Community! Die Ehemaligen-Organisation der Universität St.Gallen zählt mit rund 33’000 Mitgliedern zu den bedeutendsten Alumni-Vereinigungen Europas – und es freut uns, dass du dazu zählst! Mit uns bleibst du in Verbindung mit deinen Studienfreunden, knüpfst neue Kontakte und kannst von einem Angebot an über 350 interessanten Veranstaltungen pro Jahr – auch online – sowie weiteren tollen Services profitieren.')}
                    </p>
                    <p>
                        {$_('Wir werden auch vor Ort bei deiner Graduation-Feier dabei sein, um dich willkommen zu heissen. Komm vor dem Apéro doch bei uns im Wartebereich vorbei und schiesse dein ganz persönliches Graduierungsfoto. An unseren beiden Photo Booths kannst du gratis ein physisches und digitales Erinnerungsfoto machen, um dich auch Jahre später an deinen Meilenstein zu erinnern.')}
                    </p>
                    <p>
                        {$_('Zusätzlich laden wir dich herzlich zu deiner Graduation Party im IVY Club ab 23 Uhr ein. Wir freuen uns, mit dir zu feiern!')}
                    </p>
                </div>
            </div>
        </div>
    </div>
</main>