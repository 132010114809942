<script>
    import { _ } from 'svelte-i18n'
    import {asset} from "../../lib/utils"
</script>

<main class="relative" style="min-height: var(--appHeight); background: url({asset('images/master-graduation01.jpg')})">
    <div class="absolute top-0 left-0 w-full h-full backdrop-brightness-50"></div>
    <div class="py-32 z-10 text-white relative">
        <div class="container mx-auto p-8 bg-black/30 relative">
            <h1>
                {$_('Veranstaltungsort & Anfahrt')}
            </h1>

            <div class="mt-6">
                <p>
                    {$_('Bitte nutzen Sie den ausgeschilderten hinteren Halleneingang, bei der Kreuzung St. Jakob-Strasse/Rosenheimstrasse. Die Eingänge für Gäste und Graduierende sind getrennt. Graduierende die auf barrierefreie Wege angewiesen sind, ersuchen wir, den ausgeschilderten barrierefreien Eingang, der mit dem Eingang für Gäste zusammenfällt zu verwenden. Auf dem Gelände der Olma Messen finden Sie ein Parkhaus, welches Sie für Ihren Besuch nutzen können. Bitte beachten Sie, dass nur begrenzt Parkplätze zur Verfügung stehen und wir keine Garantie für freie Parkplätze geben können. Deshalb bitten wir Sie, ausreichend Zeit für die Anreise einzuplanen. Da nur begrenzt Parkplätze zur Verfügung stehen bitten wir Sie, das Parkhaus nach Ihrer Feier wieder zu verlassen damit die nächsten Graduierenden einen freien Platz finden. In der näheren Umgebung befinden sich weitere Parkmöglichkeiten. Die Parkhäuser in nächster Nähe der Olma sind der Spelteriniplatz und das Cityparking/Athletikzentrum.')}
                </p>
            </div>

            <div class="mt-6">
                <img src="{asset('images/anfahrt.jpg')}" alt="Anfahrtsplan">

                <div class="sticky bottom-0 flex bg-black/50 gap-4 p-4">
                    <a href="{asset('images/anfahrt.jpg')}" target="_blank" download class="button secondary w-auto">
                        {$_('Anfahrtsplan herunterladen')}
                    </a>
                </div>
            </div>
        </div>
    </div>
</main>