<script>
    import {_} from 'svelte-i18n'
    import * as graduationEventParticipantService from "../services/graduationEventParticipantService"
    import {graduationEventParticipant} from "../stores/graduationEventParticipant"
    import {failure} from "../lib/toast"
    import Input from "../components/form/Input"
    import {asset} from "../lib/utils"

    let loading = true
    let formLoading = false
    let error = false

    export let params

    $: currentGraduationEventParticipantID = $graduationEventParticipant?.data?.id

    const getGraduationEventParticipant = async () => {
        return await graduationEventParticipantService.showByGraduationEventParticipantId(params.id)
            .then( (response) => {
                loading = false
            })
            .catch( () => {
                error = true
            })
    }

    // load the user if id exists
    if(params.id) {
        getGraduationEventParticipant()
    }
    else {
        loading = false
    }

    const addGuest = () => {
        if($graduationEventParticipant.data.graduationEventTimeSlot.restrict_max_guests === false || $graduationEventParticipant.data.graduationEventParticipants.length < $graduationEventParticipant.data.graduationEventTimeSlot.max_guests) {
            $graduationEventParticipant.data.graduationEventParticipants = [...$graduationEventParticipant.data.graduationEventParticipants, {
                first_name: null,
                last_name: null,
                email: null
            }];
        }
        else {
            failure($_('Sie haben die maximale Anzahl an Gästen hinzugefügt'))
        }
    }

    const removeGuest = (graduationEventParticipantData) => {
        if(graduationEventParticipantData.id) {
            graduationEventParticipantService.destroy(graduationEventParticipantData).then( () => {
                getGraduationEventParticipant()
            })
        }
        else{
            $graduationEventParticipant.data.graduationEventParticipants = $graduationEventParticipant.data.graduationEventParticipants.filter(
                graduationEventParticipant => graduationEventParticipant !== graduationEventParticipantData
            )
        }
    }

    const store = () => {
        let promisses = []
        formLoading = true

        if($graduationEventParticipant.data.graduationEventTimeSlot.allow_event_participant_guest_update) {
            $graduationEventParticipant.data.graduationEventParticipants.forEach((graduationEventParticipant, index) => {
                if(graduationEventParticipant.id) {
                    // update
                    promisses.push(
                        graduationEventParticipantService.update(graduationEventParticipant, {
                            formID: 'graduation-registration-' + index
                        })
                    )
                }
                else {
                    // store
                    graduationEventParticipant.graduation_event_participant_id = currentGraduationEventParticipantID
                    promisses.push(
                        graduationEventParticipantService.store(graduationEventParticipant, {
                            formID: 'graduation-registration-' + index
                        })
                    )
                }
            });
        }
        else {
            failure($_('Sie können Ihre Gäste nicht mehr bearbeiten'))
        }

        // update the main user
        promisses.push(
            graduationEventParticipantService.update($graduationEventParticipant.data, {
                formID: 'graduation-registration'
            })
        )

        Promise.all(promisses)
            .then( () => {
                getGraduationEventParticipant().then( () => {
                    formLoading = false
                    document.getElementById('ticket-modal').checked = true;
                })
            })
            .catch( () => {
                getGraduationEventParticipant().then( () => {
                    formLoading = false
                })
            })
    }

    const sendTicketMail = () => {
        formLoading = true
        graduationEventParticipantService.sendTicketMail($graduationEventParticipant.data)
            .then( () => {
                document.getElementById('ticket-modal').checked = false;
            })
            .finally( () => {
                formLoading = false
            })
    }

    $: formatDate = (date) => {
        return moment(date).format('DD.MM.YYYY HH:mm')
    }
</script>

<main>

    <input type="checkbox" id="ticket-modal" class="modal-toggle" />
    <label for="ticket-modal" class="modal backdrop-brightness-50 cursor-pointer">
        <label class="modal-box relative shadow-md p-4" for="" style="width: 400px">
            <div class="modal-action mb-2">
                <label for="ticket-modal" class="button primary !p-1">
                    x
                </label>
            </div>

            <h3 class="text-lg font-bold">
                {$_('Sie haben Ihre Angaben aktualisiert')}
            </h3>
            <p class="py-4">
                {$_('Über untenstehenden Button können Sie sich die Tickets erneut per E-Mail zusenden. Bitte beachten Sie das die alten Tickets Ihre Gültigkeit verlieren')}
            </p>

            <div>
                <button disabled="{formLoading}" on:click="{sendTicketMail}" class="button primary justify-center">
                    <span class="mr-1">
                        {$_('Ticketmail senden')}
                    </span>
                </button>
            </div>
        </label>
    </label>

    <div class="flex items-center justify-center h-full w-full bg-gray-300" style="background: url({asset('images/master-graduation03.jpg')}); min-height: var(--appHeight);">
        <div class="absolute top-0 left-0 w-full h-full backdrop-brightness-50"></div>
        <div class="w-full md:w-2/3 lg:w-3/4 bg-white mx-auto container p-4 my-16 z-10">
            <h1 class="text-xl">
                {$_('Graduation Days 2022')}
            </h1>
            {#if $graduationEventParticipant?.data && !loading}
                <div>
                    <p>
                        {$_('Herzlich Willkommen {name}, über diese Plattform können Sie sich und Ihre Gäste für Ihre Graduation anmelden. ', {
                            values: { 
                                name: $graduationEventParticipant.data.first_name + ' ' + $graduationEventParticipant.data.last_name 
                            } 
                        })}
                        
                        {#if $graduationEventParticipant.data.graduationEventTimeSlot.restrict_max_guests}
                            <span>
                                {$_('Bitte beachten Sie das Sie maximal {guests} Gäste hinzufügen können.', { 
                                    values: { 
                                        guests: $graduationEventParticipant.data.graduationEventTimeSlot.max_guests 
                                    }
                                })}
                            </span>
                        {/if}
                    </p>

                    <div class="flex flex-col lg:flex-row mt-10">
                        <div class="w-full lg:w-1/2 pb-4 lg:pb-0 lg:pr-4 border-b lg:border-b-0 lg:border-r border-gray-400">
                            <h2 class="text-lg mb-2">
                                {$_('Ihre Graduation')}
                            </h2>
                            <p>
                                Name: {$graduationEventParticipant.data.first_name} {$graduationEventParticipant.data.last_name}
                            </p>
                            <p>
                                Email: {$graduationEventParticipant.data.email}
                            </p>
                            <p>
                                {$_('Uhrzeit')}:
                                {formatDate($graduationEventParticipant.data.graduationEventTimeSlot.starting_at)}
                                 - 
                                {formatDate($graduationEventParticipant.data.graduationEventTimeSlot.ending_at)}
                            </p>
                            <br>
                            {#if $graduationEventParticipant.data.graduationEventTimeSlot.title}
                                <strong>
                                    {$graduationEventParticipant.data.graduationEventTimeSlot.title}
                                </strong>
                            {/if}
                            {#if $graduationEventParticipant.data.graduationEventTimeSlot.description}
                                <div>
                                    {$graduationEventParticipant.data.graduationEventTimeSlot.description}
                                </div>
                            {/if}
                            
                            <div class="mt-4">
                                <div class="flex flex-row justify-between mt-2">
                                    <button on:click="{sendTicketMail}" class="button primary justify-center" disabled="{formLoading}">
                                        <span class="mr-1">
                                            {$_('Ticketmail senden')}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/2 pt-4 lg:pt-0 lg:pl-4">
                            <div>
                                <form on:submit="{store}">
                                    <h2 class="text-lg mb-2">
                                        {$_('Ihre Daten')}:
                                    </h2>
                                    <p class="block">
                                        {$_('Passen Sie Ihre E-Mail Adresse an')}
                                    </p>
                                    <small>
                                        {$_('An diese erhalten Sie die Tickets')}
                                    </small>
                                    <Input 
                                        bind:value={$graduationEventParticipant.data.email} 
                                        name="email" 
                                        formID="graduation-registration" 
                                        inputClass="w-full" 
                                        type="email" 
                                        placeholder="Email"
                                    />

                                    <hr class="border-gray-400 my-6">

                                    <h2 class="text-lg mb-2">
                                        {$_('Ihre Gäste')}:
                                    </h2>

                                    {#if $graduationEventParticipant.data.graduationEventParticipants.length < 1}
                                        <p>
                                            {$_('Sie haben noch keine Gäste hinzugefügt, dies können Sie über den Button "Gast hinzufügen" machen.')}

                                            {#if $graduationEventParticipant.data.graduationEventTimeSlot.restrict_max_guests}
                                                <span>
                                                    {$_('Bitte beachten Sie das Sie maximal {guests} Gäste hinzufügen können.', { values: { guests: $graduationEventParticipant.data.graduationEventTimeSlot.max_guests } })}
                                                </span>
                                            {/if}
                                        </p>
                                    {/if}

                                    {#each $graduationEventParticipant.data.graduationEventParticipants as guest, index}
                                        <strong class="block mt-4">
                                            {$_('Gast')} {index + 1}
                                        </strong>
                                        <div class="flex flex-row gap-2 items-top">
                                            <div class="grow">
                                                <Input 
                                                    bind:value={guest.first_name} 
                                                    name="first_name" 
                                                    formID="graduation-registration-{index}" 
                                                    inputClass="w-full" 
                                                    type="text" 
                                                    placeholder="{$_('Vorname')}"
                                                />
                                                <Input 
                                                    bind:value={guest.last_name} 
                                                    name="last_name" 
                                                    formID="graduation-registration-{index}" 
                                                    inputClass="w-full" 
                                                    type="text" 
                                                    placeholder="{$_('Nachname')}"
                                                />
                                            </div>
                                            <div on:click="{removeGuest(guest)}" class="cursor-pointer w-8 h-8 bg-red-500 flex items-center justify-center mt-2">
                                                <span class="material-icons-outlined text-white">
                                                    delete
                                                </span>
                                            </div>
                                        </div>
                                    {/each}
                                </form>
                            </div>
                            <div class="flex flex-row justify-between mt-2">
                                {#if $graduationEventParticipant.data.graduationEventTimeSlot.restrict_max_guests === false || $graduationEventParticipant.data.graduationEventParticipants.length < $graduationEventParticipant.data.graduationEventTimeSlot.max_guests}
                                    <button disabled="{formLoading}" on:click="{addGuest}" class="button secondary justify-center">
                                        <span class="material-icons-outlined">
                                            add
                                        </span>
                                        <span class="mr-1">
                                            {$_('Gast hinzufügen')}
                                        </span>
                                    </button>
                                {/if}

                                <button disabled="{formLoading}" on:click="{store}" class="button primary justify-center">
                                    <span class="mr-1">
                                        {$_('Speichern')}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            {:else if !loading || error}
                <div>
                    {$_('Bitte verwenden Sie den Link aus der E-Mail um auf die Seite zugreifen zu können')}
                </div>
            {:else}
                <div>
                    {$_('Lädt...')}
                </div>
            {/if}
        </div>
    </div>
</main>