<script>
    import {_} from 'svelte-i18n'
    import Banner from "../components/Banner.svelte"
    import BigLink from "../components/BigLink.svelte";
    import WorldMap from "../components/WorldMap.svelte";
    import api from '../lib/api';
    import {getVerticalScroll} from "../lib/utils"
    import {asset} from "../lib/utils"

    $: verticalScroll = 0
    let currentClapCount = 0

    document.addEventListener('scroll', () => {
        verticalScroll = getVerticalScroll()
    })

    const clap = () => {
        var country = localStorage.getItem("country");

        api.post('/user-clap', {
            country: country,
            celebration: 'bachelor'
        })
    }

    const countClaps = () => {
        window.Echo.channel('clap')
            .listen('.clapEvent', (e) => {
                e.type === 'clap'
                    ? currentClapCount = currentClapCount + 1
                    : currentClapCount > 0 
                        ? currentClapCount = currentClapCount - 1 
                        : currentClapCount = 0
            });
    }

    // set the last page for programme page
    localStorage.setItem('lastpage', 'bachelor')

    countClaps()
</script>

<main class="relative" style="min-height: var(--appHeight);" id="start">
    <Banner moveOnScroll="{true}">
        <div slot="image" class="w-full h-full">
            <img 
                class="w-full h-full object-cover" 
                src="{asset('images/master-graduation03.jpg')}" 
                alt="Graduation Banner"
            />
        </div>
        <div slot="content" class="text-white">
            <h1 class="text-4xl">
                {$_('Herzlich Willkommen')}
            </h1>
            <p class="text-lg">
                {$_('zu Ihrer Bachelor Graduation')}
            </p>
        </div>
    </Banner>

    <div class="py-8 lg:py-32 z-30 text-white relative" id="intro">
        <div class="container mx-auto p-8 {verticalScroll > 500 ? 'bg-black/30' : ''}">
            <h2>
                {$_('Herzlich Willkommen zu Ihrer Bachelor Graduation')}
            </h2>

            <div class="mt-4 w-full">
                <div class="flex flex-col lg:flex-row gap-8">
                    <div class="w-full flex flex-col lg:flex-row gap-8">
                        <div class="w-full">
                            <p>
                                {$_('Wir freuen uns, Ihnen am 01. Oktober 2022 zu Ihrem erfolgreichen B.A. (HSG) Abschluss gratulieren zu können und laden Sie herzlich zu Ihrer Bachelor Graduation Feier ein! Bitte beachten Sie, dass die Graduation Zeremonie in den Olma Messen stattfindet. Hier auf der Graduation Days Event-Seite finden Sie alle notwendigen Informationen zum Ablauf der Feier, dem Rahmenprogramm, dem Veranstaltungsort sowie zur Ticket Reservierung.')}
                            </p>
                            <p>
                                {$_('Sie haben von uns eine Einladung zu Ihrer Graduation auf Compass erhalten. Bitte beziehen Sie über den darin enthaltenen Link Ihr eigenes Ticket sowie allenfalls für Ihre bis zu vier Gäste.')}
                            </p>
                            <strong>
                                {$_('Bitte beachten Sie, dass wir nur bis zum 28.09. vier Gästeplätze garantieren können. Ab dem 29.09., um 07:00 Uhr werden nicht verwendete Plätze für alle Graduierenden zur Buchung frei gegeben.')}
                            </strong>
                        </div>
                    </div>
                </div>
                <p class="mt-8">
                    {$_('Bei Fragen stehen wir Ihnen gerne per Mail an')}
                    <a class="text-green" href="mailto:graduationday@unisg.ch" title="graduationday@unisg.ch">
                        graduationday@unisg.ch
                    </a>
                    {$_('zur Verfügung.')}
                    <br>
                    {$_('Wir freuen uns, Sie bald an Ihrer Graduation Feier zu begrüssen und wünschen Ihnen schon jetzt einen tollen Bachelor Graduation Day!')}
                </p>
            </div>
        </div>
    </div>

    <div class="py-8 lg:py-32 z-30 text-white relative" id="informationen">
        <div class="container mx-auto p-8 {verticalScroll > 500 ? 'bg-black/30' : ''}">
            <h3>
                {$_('Das wichtigste auf einen Blick')}
            </h3>

            <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-8">
                <BigLink internalLink="true" href="/veranstaltungsort" title="{$_('Mehr erfahren')}">
                    <div slot="content">
                        <p class="text-xl">
                            {$_('Veranstaltungsort & Anfahrt')}
                        </p>
                        <p>
                            {$_('Hier finden Sie alle Informationen über den Veranstaltungsort und die Anfahrt')}
                        </p>
                    </div>
                </BigLink>
                <BigLink internalLink="true" href="/einlass" title="{$_('Mehr erfahren')}">
                    <div slot="content">
                        <p class="text-xl">
                            {$_('Einlass')}
                        </p>
                        <p>
                            {$_('Hier finden Sie alle Informationen zum Einlass und Ticketverfahren')}
                        </p>
                    </div>
                </BigLink>
                <BigLink internalLink="true" href="/rahmenprogramm" title="{$_('Mehr erfahren')}">
                    <div slot="content">
                        <p class="text-xl">
                            {$_('Rahmenprogramm')}
                        </p>
                        <p>
                            {$_('Alles über Ihr Rahmenprogramm vor und nach Ihrer Graduation')}
                        </p>
                    </div>
                </BigLink>
                <BigLink internalLink="true" href="/uebernachtung" title="{$_('Mehr erfahren')}">
                    <div slot="content">
                        <p class="text-xl">
                            {$_('Übernachtung')}
                        </p>
                        <p>
                            {$_('Hier finden Sie alle Informationen zu Übernachtungsmöglichkeiten')}
                        </p>
                    </div>
                </BigLink>
                <BigLink internalLink="true" href="/fotoservice" title="{$_('Mehr erfahren')}">
                    <div slot="content">
                        <p class="text-xl">
                            {$_('Fotoservice & Fotobox')}
                        </p>
                        <p>
                            {$_('Alles über den Fotoservice an Ihrer Graduation Feier und die Fotobox')}
                        </p>
                    </div>
                </BigLink>
            </div>
        </div>
    </div>

    <div class="py-8 lg:py-32 z-30 text-white relative" id="livestream">
        <div class="container mx-auto p-8 {verticalScroll > 500 ? 'bg-black/30' : ''}">
            <h3>
                {$_('Livestream')}
            </h3>

            <div class="mx-auto shadow-md mt-4 relative">
                <div style="position:relative;overflow:hidden;height:0;padding-bottom:56.25%">
                    <iframe title="Bachelor Stream" 
                        style="position:absolute;top:0;left:0;width:100%;height:100%;border:none" 
                        src="https://embed.upstream-cloud.ch/live/6221db0ff12cf738a131676d" 
                        allowfullscreen 
                        scrolling="no" 
                        frameborder="0" 
                        allowTransparency="true">
                    </iframe>
                </div>

                <div class="bg-black/50 p-3 sticky bottom-0">
                    <div class="flex flex-row gap-4 justify-center">
                        <div class="border border-green text-white p-2 px-3 flex justify-center items-center">
                            {currentClapCount}
                        </div>
                        <button on:click="{clap}" class="button primary justify-center">
                            <span class="mr-1">
                                {$_('Applaudieren')}
                            </span>
                        </button>
                        <button on:click="{() => document.getElementById('programm').scrollIntoView()}" class="button secondary justify-center">
                            <span class="mr-1">
                                {$_('Zum Programm')}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="py-8 lg:py-32 z-30 text-white relative" id="live-karte">
        <div class="container mx-auto p-8 {verticalScroll > 500 ? 'bg-black/30' : ''}">
            <h3>
                {$_('Live Karte')}
            </h3>
            <p>
                {$_('Verfolgen Sie hier von wo aus Ihre Gäste die Zeremonie live erleben.')}
            </p>

            <div class="mx-auto shadow-md mt-4 relative">
                <WorldMap></WorldMap>

                <div class="bg-black/50 p-3 sticky bottom-0">
                    <div class="flex flex-row gap-4 justify-center">
                        <div class="border border-green text-white p-2 px-3 flex justify-center items-center">
                            {currentClapCount}
                        </div>
                        <button on:click="{clap}" class="button primary justify-center">
                            <span class="mr-1">
                                {$_('Applaudieren')}
                            </span>
                        </button>
                        <button on:click="{() => document.getElementById('livestream').scrollIntoView()}" class="button secondary justify-center">
                            <span class="mr-1">
                                {$_('Zum Livestream')}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="py-8 lg:py-32 z-30 text-white relative" id="programm">
        <div class="container mx-auto p-8 {verticalScroll > 500 ? 'bg-black/30' : ''}">
            <h3>
                {$_('Programm - 01. Oktober 2022')}
            </h3>

            <div class="flex flex-col gap-8 mt-8">
                <div class="border-b border-green py-4">
                    <h4>
                        {$_('Erste Feier, 10.15 - 11.15 Uhr')}
                    </h4>
                    <p>
                        {$_('BBWL (A-Gi)')}
                    </p>
                </div>
                <div class="border-b border-green py-4">
                    <h4>
                        {$_('Zweite Feier, 12.30 - 14.00 Uhr')}
                    </h4>
                    <p>
                        {$_('BBWL (Go-R)')}
                    </p>
                </div>
                <div class="border-b border-green py-4">
                    <h4>
                        {$_('Dritte Feier, 15.15 - 16.45 Uhr')}
                    </h4>
                    <p>
                        {$_('BBWL (S-Z), BVWL')}
                    </p>
                </div>
                <div class="border-b border-green py-4">
                    <h4>
                        {$_('Vierte Feier, 18.00 - 19.30 Uhr')}
                    </h4>
                    <p>
                        {$_('BIA, BLaw, BLE')}
                    </p>
                </div>
            </div>
        </div>
    </div>

</main>