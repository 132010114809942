<script>
    import Chocolat from "chocolat"
    import Masonry from "masonry-layout"
    import imagesLoaded from "imagesloaded"
    import {onMount} from "svelte"
    import {asset} from "../lib/utils"
    import { _ } from 'svelte-i18n'

    let grid = null
    let loading = true

    Number.prototype.pad = function(size) {
        var s = String(this);
        while (s.length < (size || 2)) {s = "0" + s;}
        return s;
    }

    onMount( () => {
        imagesLoaded(grid, () => {
            new Masonry( grid, {
                itemSelector: '.grid-item',
                columnWidth: '.grid-sizer',
                percentPosition: true,
            });

            Chocolat(document.querySelectorAll('.chocolat-parent .chocolat-image'))

            loading = false
        })
    })
</script>

<main class="relative" style="min-height: var(--appHeight)">
    <div class="absolute top-0 left-0 w-full h-full"></div>
    <div class="py-32 z-10 text-white relative">
        <div class="container mx-auto p-8 bg-black/30">
            <h1>
                {$_('Bachelor Feier 1')}
            </h1>

            <div class="mt-8">
                {#if loading}
                    <div>
                        <p>Loading Images...</p>
                    </div>
                {/if}
                <div class="grid chocolat-parent -m-2 z-0" bind:this="{grid}">
                    <div class="grid-sizer w-full md:w-[33%] lg:w-[25%] z-0"></div>
                    {#each {length: 95} as _, i}
                        <div class="grid-item w-full md:w-[33%] lg:w-[25%] group p-2">
                            <a on:click|self="{() => {}}" href="{asset('/images/master_images/feier_03/Stimmungsbilder_Feier_03_' + (i + 1).pad(3) + '.jpg')}" download class="bg-green p-1 flex items-center justify-center w-8 h-8 rounded-md absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-all ease-in-out z-10">
                                <span class="material-icons-outlined">
                                file_download
                                </span>
                            </a>
                            <a href="{asset('/images/master_images/feier_03/Stimmungsbilder_Feier_03_' + (i + 1).pad(3) + '.jpg')}" class="relative w-full h-full chocolat-image z-0">
                                <img src="{asset('/images/master_images/feier_03/Stimmungsbilder_Feier_03_' + (i + 1).pad(3) + '.jpg')}" alt="" class="w-full h-full">
                            </a>
                        </div>
                    {/each}
                </div>
            </div>
        </div>
    </div>
</main>