<script>
    import { _ } from 'svelte-i18n'
    import {asset} from "../../lib/utils"
</script>

<main class="relative" style="min-height: var(--appHeight);">
    <div class="py-32 z-10 relative">
        <div class="container mx-auto">
            <h1>{$_('Impressum')}</h1>
            <h2>{$_('HSG Events Office')}</h2>

            <p>
            <br>
            {$_('Universität St.Gallen')}
            <br><br>
            Guisanstrasse 1a<br>
            CH-9010 St.Gallen<br>
            Tel. +41 71 224 23 34<br>
            E-Mail: <a class="text-green" href="mailto:eventsoffice@unisg.ch">eventsoffice@unisg.ch</a><br>
            <br><br>
            Universität St.Gallen – Hochschule<br>
            für Wirtschafts-, Rechts- und<br>
            Sozialwissenschaften sowie<br>
            Internationale Beziehungen (HSG)<br>
            </p>
            
        </div>
    </div>
</main>