<script>
    import {locale} from 'svelte-i18n'

    let selected
    
    const handleLocaleChange = event => {
        event.preventDefault();
        locale.set(selected);
    };
</script>

<div class="choose-locale">
    <div class="select">
        <select class="form-control rounded-none" bind:value="{selected}" on:change={handleLocaleChange}>
            <option selected="{$locale === 'en' ? true : false}" value="en">English</option>
            <option selected="{$locale === 'de' || $locale === 'de-DE' ? true : false}" value="de">Deutsch</option>
        </select>
    </div>
</div>