import api from "../lib/api";
import { graduationEventParticipant, graduationEventParticipants } from "../stores/graduationEventParticipant"
import {success, warning, failure} from "../lib/toast"
import {navigate} from "svelte-routing";

let apiUrl = "/api/graduation-event-participant"

export async function index(config) {
    return await api.get(`${apiUrl}`, config)
        .then( (response) => {
            graduationEventParticipants.set(response.data)
            return response
        })
        .catch( (error) => {
            return Promise.reject(error)
        })
}

export async function store(data, config) {
    return await api.post(`${apiUrl}`, data, config)
        .then( (response) => {
            return response
        })
        .catch( (error) => {
            return Promise.reject(error)
        })
}

export async function show(data, config) {
    return await api.get(`${apiUrl}/${data.id}`, config)
        .then( (response) => {
            success('Ihr Gast wurde gespeichert')
            graduationEventParticipant.set(response.data)
            return response
        })
        .catch( (error) => {
            failure('Es gab einen Fehler beim speichern Ihres Gastes')
            return Promise.reject(error)
        })
}

export async function update(data, config) {
    return await api.put(`${apiUrl}/${data.id}`, data, config)
        .then( (response) => {
            success('Ihre Änderungen wurden gespeichert')
            return response
        })
        .catch( (error) => {
            failure('Es gab einen Fehler beim speichern Ihrer Änderungen')
            return Promise.reject(error)
        })
}

export async function destroy(data, config) {
    return await api.delete(`${apiUrl}/${data.id}`, config)
        .then( (response) => {
            return response
        })
        .catch( (error) => {
            return Promise.reject(error)
        })
}

export async function showByGraduationEventParticipantId(id) {
    return await api.get(`${apiUrl}/showByGraduationEventParticipantId/${id}`)
        .then( (response) => {
            graduationEventParticipant.set(response.data)
            return response
        })
        .catch( (error) => {
            return Promise.reject(error)
        })
}

export async function sendTicketMail(data, config) {
    return await api.put(`${apiUrl}/sendTicketMail/${data.id}`, data, config)
        .then( (response) => {
            success('Sie erhalten in kürze eine Mail')
            return response
        })
        .catch( (error) => {
            return Promise.reject(error)
        })
}

export async function checkin(graduationEventParticipantID, config) {
    return await api.put(`${apiUrl}/checkin/${graduationEventParticipantID}`, config)
        .then( (response) => {
            return response
        })
        .catch( (error) => {
            return Promise.reject(error)
        })
}

export async function checkout(graduationEventParticipantID, config) {
    return await api.put(`${apiUrl}/checkout/${graduationEventParticipantID}`, config)
        .then( (response) => {
            return response
        })
        .catch( (error) => {
            return Promise.reject(error)
        })
}