import api from "../lib/api";
import { tempEvent, tempEvents } from "../stores/tempEvent"
import {success, warning, failure} from "../lib/toast"
import {navigate} from "svelte-routing"

let apiUrl = "/api/temp-event"

export async function index(config) {
    return await api.get(`${apiUrl}`, config)
        .then( (response) => {
            tempEvents.set(response.data)
            return response
        })
        .catch( (error) => {
            return Promise.reject(error)
        })
}

export async function register(data, config) {
    return await api.post(`${apiUrl}/register`, data, config)
        .then( (response) => {
            success('Ihre Anmeldung war erfolgreich. Sie erhalten in kürze eine Mail')
            return response
        })
        .catch( (error) => {
            _.forEach(error.response.data.errors, errors => {
                _.forEach(errors, error => {
                    failure(error)
                })
            });

            return Promise.reject(error)
        })
}