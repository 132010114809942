import GraduationHome from "./views/GraduationHome.svelte"
import GraduationCheckin from "./views/GraduationCheckin.svelte"
import GraduationRegistration from "./views/GraduationRegistration.svelte"
import GraduationRegistrationFallback from "./views/GraduationRegistrationFallback.svelte"
import BachelorGraduation from "./views/BachelorGraduation.svelte"
import Bachelor_Gallery_1 from "./views/Bachelor_Gallery_1.svelte"
import Bachelor_Gallery_2 from "./views/Bachelor_Gallery_2.svelte"
import Bachelor_Gallery_3 from "./views/Bachelor_Gallery_3.svelte"
import Bachelor_Gallery_4 from "./views/Bachelor_Gallery_4.svelte"
import Master_Gallery_1 from "./views/Master_Gallery_1.svelte"
import Master_Gallery_2 from "./views/Master_Gallery_2.svelte"
import Master_Gallery_3 from "./views/Master_Gallery_3.svelte"
import Master_Gallery from "./views/Master_Gallery.svelte"
import MasterGraduation from "./views/MasterGraduation.svelte"
import Einlass from "./views/Contentpages/Einlass.svelte"
import Fotoservice from "./views/Contentpages/Fotoservice.svelte"
import Rahmenprogramm from "./views/Contentpages/Rahmenprogramm.svelte"
import Tickets from "./views/Contentpages/Tickets.svelte"
import Uebernachtung from "./views/Contentpages/Uebernachtung.svelte"
import Veranstaltungsort from "./views/Contentpages/Veranstaltungsort.svelte"
import Impressum from "./views/Contentpages/Impressum.svelte"

export const routes = [
    {
        name: 'Start',
        path: '/',
        component: GraduationHome,
        auth: false,
        menu: true
    },
    {
        name: 'Graduation Event',
        path: '/graduation-checkin/',
        component: GraduationCheckin,
        auth: false,
    },
    {
        name: 'Graduation Event',
        path: '/graduation-registration/',
        component: GraduationRegistration,
        auth: false,
    },
    {
        name: 'Graduation Event',
        path: '/graduation-%20registration/',
        component: GraduationRegistration,
        auth: false,
    },
    {
        name: 'Graduation Event',
        path: '/graduation-%20registration/:id',
        component: GraduationRegistration,
        auth: false,
    },
    {
        name: 'Graduation Event',
        path: '/graduationregistration/',
        component: GraduationRegistration,
        auth: false,
    },
    {
        name: 'Graduation Event',
        path: '/graduation-registration/:id',
        component: GraduationRegistration,
        auth: false,
    },
    {
        name: 'Graduation Event',
        path: '/graduationregistration/:id',
        component: GraduationRegistration,
        auth: false,
    },
    {
        name: 'Bachelor Graduation',
        path: '/bachelor-graduation',
        component: BachelorGraduation,
        auth: false,
        menu: true
    },
    {
        name: 'Master Graduation',
        path: '/master-graduation',
        component: MasterGraduation,
        auth: false,
        menu: true
    },

    // Contentpages
    {
        name: 'Einlass',
        path: '/einlass',
        component: Einlass,
        auth: false,
        menu: false
    },
    {
        name: 'Fotoservice',
        path: '/fotoservice',
        component: Fotoservice,
        auth: false,
        menu: false
    },
    {
        name: 'Rahmenprogramm',
        path: '/rahmenprogramm',
        component: Rahmenprogramm,
        auth: false,
        menu: false
    },
    {
        name: 'Tickets',
        path: '/tickets',
        component: Tickets,
        auth: false,
        menu: false
    },
    {
        name: 'Bachelor Gallery 1',
        path: '/bachelor-galerie-1',
        component: Bachelor_Gallery_1,
        auth: false,
        menu: false
    },
    {
        name: 'Bachelor Gallery 2',
        path: '/bachelor-galerie-2',
        component: Bachelor_Gallery_2,
        auth: false,
        menu: false
    },
    {
        name: 'Bachelor Gallery 3',
        path: '/bachelor-galerie-3',
        component: Bachelor_Gallery_3,
        auth: false,
        menu: false
    },
    {
        name: 'Bachelor Gallery 4',
        path: '/bachelor-galerie-4',
        component: Bachelor_Gallery_4,
        auth: false,
        menu: false
    },
    {
        name: 'Master Gallery 1',
        path: '/master-galerie-1',
        component: Master_Gallery_1,
        auth: false,
        menu: false
    },
    {
        name: 'Master Gallery 2',
        path: '/master-galerie-2',
        component: Master_Gallery_2,
        auth: false,
        menu: false
    },
    {
        name: 'Master Gallery 3',
        path: '/master-galerie-3',
        component: Master_Gallery_3,
        auth: false,
        menu: false
    },
    {
        name: 'Master Gallery',
        path: '/master-galerie',
        component: Master_Gallery,
        auth: false,
        menu: false
    },
    {
        name: 'Uebernachtung',
        path: '/uebernachtung',
        component: Uebernachtung,
        auth: false,
        menu: false
    },
    {
        name: 'Veranstaltungsort',
        path: '/veranstaltungsort',
        component: Veranstaltungsort,
        auth: false,
        menu: false
    },
    {
        name: 'Impressum',
        path: '/impressum',
        component: Impressum,
        auth: false,
        menu: false
    },
]