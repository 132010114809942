<script>
    import InputError from "./InputError.svelte"

    // props
    export let autocomplete = false
    export let disabled = false
    export let name = ""
    export let placeholder = ""
    export let required = false
    export let type = "text"
    export let value = ""

    export let inputClass= ""
    export let containerClass= ""

    export let formID = ""

    let hasErrors
</script>

<div class="{containerClass}">
    <input 
        {autocomplete}
        {disabled}
        {name}
        {placeholder}
        {required}
        {type}
        {value}
        on:input={(event) => value = event.target.value}
        class="form-control w-full border {inputClass} {hasErrors ? 'border-red-500 placeholder:text-red-500 text-red-500' : ''}"
    />

    <InputError 
        bind:hasErrors="{hasErrors}"
        {name}
        {formID}
    />
</div>