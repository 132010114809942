<script>
    import { _ } from 'svelte-i18n'
    import PdfViewer from '../../components/PdfViewer.svelte';
    import {asset} from "../../lib/utils"
</script>

<main class="relative" style="min-height: var(--appHeight); background: url({asset('images/master-graduation01.jpg')})">
    <div class="absolute top-0 left-0 w-full h-full backdrop-brightness-50"></div>
    <div class="py-32 z-10 text-white relative">
        <div class="container mx-auto p-8 bg-black/30">
            <h1>
                {$_('Ihre Übernachtungsmöglichkeiten')}
            </h1>

            <div class="mt-6">
                <p>
                    {$_('Entspannt feiern und zum Spezialpreis übernachten. Bitte informieren Sie sich hier über das Graduation Angebot im HSG Alumni Haus des Weiterbildungszentrums Holzweid.')}
                    <a target="_blank" href="https://st.gallen-bodensee.ch/de/">{$_('Weitere Übernachtungsangebote finden Sie hier')}</a>
                </p>
            </div>

            <div class="mt-6">
                <PdfViewer url="{asset('pdfs/graduation_package_de.pdf')}"/>
            </div>
        </div>
    </div>
</main>