<script>
    import { _ } from 'svelte-i18n'
    import {getVerticalScroll} from "../lib/utils"

    export let moveOnScroll = false

    $: verticalScroll = 0

    document.addEventListener('scroll', () => {
        verticalScroll = getVerticalScroll()
    })
</script>

<main class="sticky top-0" style="z-index: -1">
    <div class="banner relative transition-all ease-in-out duration-500 {moveOnScroll && verticalScroll >= 300 ? '!h-screen' : ''}">
        <div class="underlay transition-all ease-in-out duration-500 {moveOnScroll && verticalScroll >= 300 ? 'no-cut !h-full' : ''}">
            <div class="overlay transition-all ease-in-out duration-500"></div>
            <slot name="image"/>
        </div>
        {#if verticalScroll <= 300 || !moveOnScroll}
            <div class="absolute top-0 right-0 w-full h-full flex flex-col justify-center items-center z-10">
                <slot name="content"/>
            </div>
        {/if}
    </div>
</main>