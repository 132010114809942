<script>
    import {Router, Route} from "svelte-routing";
    import {routes} from "../routes.js"
    import {register, init, getLocaleFromNavigator, isLoading, locale} from 'svelte-i18n'
    
    import Header from "./Header.svelte"
    import Footer from "./Footer.svelte"
    import Fallback from "../views/Fallback.svelte"

    let hideHeader = false
    let hideFooter = false

    const handleLocaleChange = e => {
        e.preventDefault();
        locale.set(e.target.value);
    };
</script>

<Router>
    <main class="w-full flex flex-col min-h-screen">
        <Header hide="{hideHeader}"/>

        {#if !$isLoading}
        <main style="min-height: var(--appHeight)">
            {#each routes as route}
                <Route path="{route.path}" let:params>
                    <svelte:component this="{route.component}" {params}
                        on:hideHeader="{(event) => hideHeader = event.detail}" 
                        on:hideFooter="{(event) => hideFooter = event.detail}"/>
                </Route>
            {/each}
            <Route to="/*" component={Fallback}/>
        </main>
        <Footer hide="{hideFooter}"/>
        {:else}
            <p>
                Loading
            </p>
        {/if}
    </main>
</Router>