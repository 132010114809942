<script>
    import {_, locale} from 'svelte-i18n'
    import {link} from "svelte-routing"
    import {asset} from "../lib/utils"

    let currentYear = moment().format('Y')

    export let hide
</script>

<div class="justify-self-end z-20 {hide ? 'hidden' : ''}" id="footer">
    <div class="primary bg-white py-8">
        <div class="container mx-auto">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4">
                <div class="col-span-1 lg:col-span-2">
                    <div class="w-full lg:w-1/2">
                        {#if $locale === 'de-DE' || $locale === 'de'}
                            <img src="{asset('images/logo.png')}" class="h-[40px]" alt="Uni St. Gallen"/>
                        {:else}
                            <img src="{asset('images/logo.png')}" class="h-[40px]" alt="Uni St. Gallen"/>
                        {/if}
                        <p class="mt-6 font-extralight text-xs">
                            &copy; {currentYear} {$_('Universität St. Gallen')}
                        </p>
                    </div>
                </div>
                <!-- {#if $settings}
                    <div class="col-span-1">
                        <h5>
                            {$settings.footer_column_heading_1}
                        </h5>
                        <div class="content">
                            {@html $settings.footer_column_content_1}
                        </div>
                    </div>
                    <div class="col-span-1">
                        <h5>
                            {$settings.footer_column_heading_2}
                        </h5>
                        <div class="content">
                            {@html $settings.footer_column_content_2}
                        </div>
                    </div>
                    <div class="col-span-1">
                        <h5>
                            {$settings.footer_column_heading_3}
                        </h5>
                        <div class="content">
                            {@html $settings.footer_column_content_3}
                        </div>
                    </div>
                {/if} -->
            </div>
        </div>
    </div>
    <div class="text-white bg-green py-2">
        <div class="container mx-auto flex flex-row gap-3 justify-center">
            <a use:link href="/impressum">
                {$_('Impressum')}
            </a>
            <a target="_blank" href="https://www.unisg.ch/de/disclosures/datenschutz">
                {$_('Datenschutz')}
            </a>
        </div>
    </div>
</div>