<script>
    import { _ } from 'svelte-i18n'
    import {asset} from "../../lib/utils"
</script>

<main class="relative" style="min-height: var(--appHeight); background: url({asset('images/master-graduation01.jpg')})">
    <div class="absolute top-0 left-0 w-full h-full backdrop-brightness-50"></div>
    <div class="py-32 z-10 text-white relative">
        <div class="container mx-auto p-8 bg-black/30">
            <h1>
                {$_('Tickets')}
            </h1>

            <div class="mt-6">
                <p>
                    {$_('Wenn Sie Ihren Nächsten die Stadt St.Gallen zeigen möchten, können Sie sich für eine der u.s. Führungen anmelden.')}
                </p>
            </div>
        </div>
    </div>
</main>