import axios from 'axios';
import _ from 'lodash';
import { errors } from '../stores/error';
import {success, warning, failure} from "../lib/toast"

// Set axios default header and Base Url
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
// axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
//

// request interceptor
axios.interceptors.request.use(async (config) => {
    // unset the errors in error store:
    if(config.formID) {
        errors.update((errors) => _.set(errors, config.formID, []))
    }

    return config;  
});

// response interceptor
axios.interceptors.response.use( response => {
    if(response.config.formID) {
        errors.update((errors) => _.set(errors, response.config.formID, []))
    }
    
    return Promise.resolve(response);
}, (error) => {
    // set the errors in error store:
    if(error.config.formID && error.response.data.errors) {
        errors.update((errors) => _.set(errors, error.config.formID, error.response.data.errors))
    }

    if(error.response.status != 401 && error.response.config.url != '/api/v1/user') {
        failure(error.response.data.message)
    }

    return Promise.reject(error);
})

export default {
    get(url, config) {
        return axios.get(url, config)
    },

    put(url, data, config) {
        return axios.put(url, data, config)
    },

    post(url, data, config) {
        return axios.post(url, data, config)
    },

    delete(url, config) {
        return axios.delete(url, config)
    }
}